import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import s from '../styles/stocna-hrana.module.css';
import { food } from '../data/stocna-hrana.json';

const getSvg = (data, name) => {
  return data.allFile.edges.filter(edge => edge.node.name === name)[0].node
    .publicURL;
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "stocna-hrana" } }) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <section className={s.stocnaHranaSection}>
        <div className="container">
          <div className="section-header">
            <h2>Proizvodnja stočne hrane</h2>
            <p>Takođe nudimo veliki asortiman stočne hrane.</p>
          </div>
          <div className="row">
            {food.map((f, index) => (
              <div key={index} className={`${s.food__item} col-md-6 col-lg-4`}>
                <span
                  className={s.image}
                  style={{ backgroundImage: `url(${getSvg(data, f.slug)})` }}
                />
                <span className={s.text}>{f.name}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);
