import React from 'react';

import s from '../styles/brasna-grid.module.css';
import { types } from '../data/tipovi-brasna.json';


export default () => (
  <section>
    <div className="container">
      <div className="section-header">
        <h2>Proizvodnja pšeničnog brašna</h2>
        <p>
          Mlin preduzeća Keso-Promet se bavi proizvodnjom pšeničnog brašna
          različitih tipova.
        </p>
      </div>
      <ul className="flour-list row mx-auto">
        {types.map(({ name }, index) => {
          return <li key={index} className="col-md-6 col-lg-4">{name}</li>;
        })}
      </ul>
    </div>
  </section>
);
