import React from 'react';

import AboutSection from '../components/about-section';
import Layout from '../components/layout';
import HeroSection from '../components/hero-section';
import BrasnaGrid from '../components/brasna-grid';
import StocnaHrana from '../components/stocna-hrana';
import Koncentrati from '../components/koncentrati';

export default () => (
  <Layout>
    <HeroSection cn="zitopromet">
      <h2>Žitopromet</h2>
    </HeroSection>
    <AboutSection secondary>
      <p>
        Keso-Promet d.o.o. je 2015. godine kupio "Žitopromet Zvornik u stečaju",
        preduzeće koje se bavilo proizvodnjom i prodajom mlinarsko–pekarskih
        proizvoda. Donijeli smo odluku da se pogon potpuno rekonstruiše i opremi
        sa novom tehnološkom opremom.
      </p>
      <p>
        Imamo potpuno nov mlin kapaciteta 150 tona na 24 sata za koji možemo
        reći da je jedan od najsavremenijih u okruženju i mješaonu stočne hrane,
        kompletno opremljeno skladište žitarica, potpuno opremljenu novu
        laboratoriju za sav program.
      </p>
    </AboutSection>
    <BrasnaGrid />
    <StocnaHrana />
    <Koncentrati />
  </Layout>
);
