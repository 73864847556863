import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import s from '../styles/koncentrati.module.css';
import { types } from '../data/koncentrati.json';

const getSvg = (data, name) => {
  return data.allFile.edges.filter(edge => edge.node.name === name)[0].node
    .publicURL;
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "koncentrati" } }) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <section className={s.koncentratiSection}>
        <div className="container">
          <div className="section-header">
            <h2>Proizvodnja koncentrata</h2>
          </div>
          <div className="row">
            {types.map(({ name, list }, index) => (
              <div className="col-md-6 col-lg-3" key={`type-${index}`}>
                <div className={s.card}>
                  <div className={s.image}>
                    <img src={`${getSvg(data, name)}`} alt="" />
                  </div>
                  <ul className={s.list}>
                    {list.map((item, index) => (
                      <li key={`item-${index}`}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);
